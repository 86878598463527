import React, { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import auth from '../../firebase.init';
import { Helmet } from 'react-helmet';

const PaymentPending = () => {
    const [user] = useAuthState(auth);
    const [loading, setLoading] = useState(true);
    const [orders, setOrders] = useState([]);
    const [userData, setUserData] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);

    // Function to fetch orders from server
    const fetchOrders = () => {
        if (user?.email) {
            fetch(`https://server.enjoywiki.com/flip-server/orders?userEmail=${user.email}`)
                .then((res) => res.json())
                .then((info) => {
                    setOrders(info.reverse());
                    setLoading(false);
                })
                .catch((error) => {
                    console.error("Error fetching orders:", error);
                    setLoading(false);
                });
        }
    };



    useEffect(() => {
        fetchOrders();
    }, [user]);

    useEffect(() => {
        if (user?.email) {
            fetch(`https://server.enjoywiki.com/flip-server/users?userEmail=${user.email}`)
                .then((res) => res.json())
                .then((info) => {
                    const matchedUser = info.find(item => item.UserEmail === user.email);
                    if (matchedUser) {
                        setUserData(matchedUser);
                    } else {
                        toast.error("User not found!");
                    }
                })
                .catch(error => {
                    console.error("Error fetching user data:", error);
                    toast.error("Error loading user data");
                });
        }
    }, [user]);

    // Filter orders with paymentStatus === "Pending"
    const pendingPayment = orders.filter(order => order.paymentStatus === "pending");



    // Pagination
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const paymentPending = pendingPayment.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(pendingPayment.length / itemsPerPage);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };


    if (!userData || loading) {
        return <div>Loading...</div>;
    }

    const getStatusBadgeClass = (status) => {
        status = status.toLowerCase();
        if (status === 'pending') return 'status-badge pending';
        return 'status-badge in-progress';
    };

    if (!userData || loading) {
        return <div>Loading...</div>;
    }

    return (
        <>
            <Helmet>
                <title>Payment Pending | FlipBundle - Complete Your Purchase</title>
                <meta name="description" content="Your payment is currently pending on FlipBundle. Complete the payment to finalize your purchase and access your digital products." />
            </Helmet>

            <main className="dashboard-main min-vh-100">
                <div className="d-flex flex-column gap-4">
                    {/* Page Header */}
                    <div className="d-flex flex-column flex-md-row gap-4 align-items-md-center justify-content-between">
                        <div>
                            <h3 className="text-24 fw-bold text-dark-300 mb-2">My Pending Orders</h3>
                            <ul className="d-flex align-items-center gap-2">
                                <li className="text-dark-200 fs-6">Buyer Dashboard</li>
                                <li>
                                    <svg xmlns="http://www.w3.org/2000/svg" width={5} height={11} viewBox="0 0 5 11" fill="none">
                                        <path d="M1 10L4 5.5L1 1" stroke="#5B5B5B" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </li>
                                <li className="text-lime-300 fs-6">My Pending Orders</li>
                            </ul>
                        </div>
                    </div>


                    {/* Latest Orders */}
                    <div>
                        <h3 className="text-24 fw-bold text-dark-300 mb-2">Latest Orders</h3>
                        <div className="overflow-x-auto">
                            <div className="w-100">
                                <table className="w-100 dashboard-table">
                                    <thead className="pb-3">
                                        <tr>
                                            <th scope="col" className="d-none d-md-table-cell">Order Date</th>
                                            <th scope="col" className="ps-4">Package Name</th>
                                            <th scope="col">Amount</th>
                                            <th scope="col" className="text-start">Payment Status</th>
                                            <th scope="col" className="d-none d-md-table-cell">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {paymentPending.map((order) => (
                                            <tr key={order._id}>
                                                <td className="d-none d-md-table-cell text-dark-200">{order.orderDate}</td>
                                                <td>
                                                    <div className="d-flex gap-3 align-items-center project-name">
                                                        <div className="order-img">
                                                            <img
                                                                src={order.featuredImage || "/assets/img/dashboard/orders/o-1.png"}
                                                                alt=""
                                                                className="rounded-circle"
                                                                style={{ width: '40px', height: '40px' }}
                                                            />
                                                        </div>
                                                        <div>
                                                            <div className="d-md-none order-date text-muted mb-2">
                                                                {order.orderDate}
                                                            </div>
                                                            <p className="project-link text-truncate d-inline-block mb-1"
                                                                style={{ maxWidth: '250px' }}>
                                                                {order.packageName}
                                                            </p>


                                                            <small className="d-block text-muted">
                                                                ID: {order.orderId}
                                                            </small>

                                                            <div className="d-md-none order-date text-muted mb-2">
                                                                <Link to={`/pay-now/${order._id}`} className="btn btn-primary">
                                                                    Pay Now
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="text-dark-200">${order.packagePrice}</td>

                                                <td>
                                                    <span className={getStatusBadgeClass(order.paymentStatus)}>
                                                        {order.paymentStatus[0].toUpperCase() + order.paymentStatus.slice(1)}
                                                    </span>
                                                </td>
                                                <td className="d-none d-md-table-cell text-dark-200">
                                                    <Link to={`/pay-now/${order._id}`} className="btn btn-primary">
                                                        Pay Now
                                                    </Link>
                                                </td>

                                            </tr>
                                        ))}
                                        {paymentPending.length === 0 && (
                                            <tr>
                                                <td colSpan="6" className="text-center py-4">
                                                    No orders found
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                                {/* Pagination */}
                                {paymentPending.length > itemsPerPage && (
                                    <div className="row justify-content-end mt-4">
                                        <div className="col-auto">
                                            <nav>
                                                <ul className="custom-pagination pagination">
                                                    <li className={`custom-page-item page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                                        <button
                                                            className="custom-page-link page-link"
                                                            onClick={() => handlePageChange(currentPage - 1)}
                                                            disabled={currentPage === 1}
                                                        >
                                                            Previous
                                                        </button>
                                                    </li>
                                                    {[...Array(totalPages)].map((_, i) => (
                                                        <li
                                                            key={i + 1}
                                                            className={`custom-page-item page-item ${currentPage === i + 1 ? 'active' : ''}`}
                                                        >
                                                            <button
                                                                className="custom-page-link page-link"
                                                                onClick={() => handlePageChange(i + 1)}
                                                            >
                                                                {i + 1}
                                                            </button>
                                                        </li>
                                                    ))}
                                                    <li className={`custom-page-item page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                                                        <button
                                                            className="custom-page-link page-link"
                                                            onClick={() => handlePageChange(currentPage + 1)}
                                                            disabled={currentPage === totalPages}
                                                        >
                                                            Next
                                                        </button>
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </main>

            <style jsx>{`
                .status-badge {
                    padding: 6px 12px;
                    border-radius: 4px;
                    font-size: 14px;
                    font-weight: 500;
                    display: inline-block;
                }
                
                .status-badge.completed {
                    background-color: #e8f5e9;
                    color: #2e7d32;
                }
                
                .status-badge.pending {
                    background-color: #fff3e0;
                    color: #ef6c00;
                }
                
                .status-badge.in-progress {
                    background-color: #e3f2fd;
                    color: #1565c0;
                }
                
                .project-link {
                    color: #333;
                    text-decoration: none;
                    font-weight: 500;
                }
                
                .project-link:hover {
                    color: #007bff;
                }
                
                .dashboard-table thead th {
                    background-color: #f8f9fa;
                    padding: 12px;
                    font-weight: 600;
                }
                
                .dashboard-table tbody td {
                    padding: 16px 12px;
                    border-bottom: 1px solid #eee;
                }
                
                .text-dark-200 {
                    color: #6c757d;
                }
                
                .text-dark-300 {
                    color: #343a40;
                }
            `}</style>
        </>
    );
};

export default PaymentPending;