import React, { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import auth from '../../../firebase.init';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const MainDashboardContent = () => {
    const [user] = useAuthState(auth);
    const [loading, setLoading] = useState(true);
    const [orders, setOrders] = useState([]);
    const [userData, setUserData] = useState(null);

    // Function to fetch orders from server
    const fetchOrders = () => {
        if (user?.email) {
            fetch(`https://server.enjoywiki.com/flip-server/orders?sellerEmail=${user.email}`)
                .then((res) => res.json())
                .then((info) => {
                    // Filter orders with paymentStatus === 'Paid'
                    const paidOrders = info.filter(order => order.paymentStatus === 'Paid');
                    setOrders(paidOrders.reverse()); // Reverse to show latest orders first
                    setLoading(false);
                })
                .catch((error) => {
                    console.error("Error fetching orders:", error);
                    setLoading(false);
                });
        }
    };


    const [products, setProducts] = useState([]);
    // Fetch products
    useEffect(() => {
        fetch(`https://server.enjoywiki.com/flip-server/products?sellerEmail=${user?.email}`)
            .then((res) => res.json())
            .then((info) => setProducts(info.reverse()));
    }, [user?.email]);

    // Calculate dashboard statistics
    const calculateDashboardStats = () => {
        // Total Earning (sum of completed/paid orders)
        const totalEarning = orders
            .filter(order =>
                order.paymentStatus.toLowerCase() === 'paid' ||
                order.paymentStatus.toLowerCase() === 'completed'
            )
            .reduce((sum, order) => {
                // Convert to number and handle potential null/undefined values
                const price = Number(order.packagePrice) || 0;
                return sum + price;
            }, 0);

        // Apply 20% deduction and round to 2 decimal places
        // Using Math.round((x * 100)) / 100 for precise decimal calculation
        const finalEarning = Math.round((totalEarning * 0.8) * 100) / 100;

        // Complete Orders count
        const completedOrders = orders.filter(
            order =>
                order.paymentStatus.toLowerCase() === 'paid' ||
                order.paymentStatus.toLowerCase() === 'completed'
        ).length;

        // Get unique products count (using packageId as unique identifier)
        const uniqueProducts = new Set(products.map(product => product.productId)).size;

        return {
            // Convert to string with exactly 2 decimal places for display
            totalEarning: finalEarning.toFixed(2),
            completedOrders,
            totalProducts: uniqueProducts
        };
    };
    useEffect(() => {
        fetchOrders();
    }, [user]);

    useEffect(() => {
        if (user?.email) {
            fetch(`https://server.enjoywiki.com/flip-server/users?userEmail=${user.email}`)
                .then((res) => res.json())
                .then((info) => {
                    const matchedUser = info.find(item => item.UserEmail === user.email);
                    if (matchedUser) {
                        setUserData(matchedUser);
                    } else {
                        toast.error("User not found!");
                    }
                })
                .catch(error => {
                    console.error("Error fetching user data:", error);
                    toast.error("Error loading user data");
                });
        }
    }, [user]);

    const getStatusBadgeClass = (status) => {
        status = status.toLowerCase();
        if (status === 'paid' || status === 'completed') return 'status-badge completed';
        if (status === 'pending') return 'status-badge pending';
        return 'status-badge in-progress';
    };

    if (!userData || loading) {
        return <div>Loading...</div>;
    }

    const stats = calculateDashboardStats();
    const formattedBalance = (parseFloat(userData.currentBalance) || 0).toFixed(2);

    return (
        <>
            <Helmet>
                <title>Seller Dashboard | FlipBundle - Manage Your Digital Products</title>
                <meta name="description" content="Access your Seller Dashboard on FlipBundle to manage your digital products, track sales, and monitor performance. Effortlessly update your listings and grow your business." />
            </Helmet>

            <main className="dashboard-main min-vh-100">
                <div className="d-flex flex-column gap-4">
                    {/* Page Header */}
                    <div className="d-flex gap-4 flex-column flex-md-row align-items-md-center justify-content-between">
                        <div>
                            <h3 className="text-24 fw-bold text-dark-300 mb-2">Seller Dashboard</h3>
                        </div>

                    </div>

                    {/* Statistics Cards */}
                    <div className="row g-4">
                        <div className="col-xl col-md-6">
                            <div className="p-4 d-flex align-items-center dashobard-widget justify-content-between bg-white rounded-4">
                                <div>
                                    <h3 className="dashboard-widget-title fw-bold text-dark-300">
                                        ${formattedBalance}
                                    </h3>
                                    <p className="text-18 text-dark-200">Current Balance</p>
                                </div>
                                <div className="dashboard-widget-icon">
                                    <img src="/assets/img/dashboard/icon/icon-1.png" alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-xl col-md-6">
                            <div className="p-4 d-flex align-items-center dashobard-widget justify-content-between bg-white rounded-4">
                                <div>
                                    <h3 className="dashboard-widget-title fw-bold text-dark-300">
                                        ${stats.totalEarning}
                                    </h3>
                                    <p className="text-18 text-dark-200">Total Earning</p>
                                </div>
                                <div className="dashboard-widget-icon">
                                    <img src="/assets/img/dashboard/icon/icon-2.png" alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-xl col-md-6">
                            <div className="p-4 d-flex align-items-center dashobard-widget justify-content-between bg-white rounded-4">
                                <div>
                                    <h3 className="dashboard-widget-title fw-bold text-dark-300">
                                        {stats.completedOrders}
                                    </h3>
                                    <p className="text-18 text-dark-200">Total Sales</p>
                                </div>
                                <div className="dashboard-widget-icon">
                                    <img src="/assets/img/dashboard/icon/icon-3.png" alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-xl col-md-6">
                            <div className="p-4 d-flex align-items-center dashobard-widget justify-content-between bg-white rounded-4">
                                <div>
                                    <h3 className="dashboard-widget-title fw-bold text-dark-300">
                                        {stats.totalProducts}
                                    </h3>
                                    <p className="text-18 text-dark-200">Total Products</p>
                                </div>
                                <div className="dashboard-widget-icon">
                                    <img src="/assets/img/dashboard/icon/icon-4.png" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Latest Orders */}
                    <div>
                        <h3 className="text-24 fw-bold text-dark-300 mb-2">Latest Orders</h3>
                        <div className="overflow-x-auto">
                            <div className="w-100">
                                <table className="w-100 dashboard-table">
                                    <thead className="pb-3">
                                        <tr>
                                            <th scope="col" className="ps-4">Product Name</th>
                                            <th scope="col">Amount</th>
                                            <th scope="col">Order Date</th>
                                            <th scope="col" className="text-start">Payment Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {orders.slice(0, 10).map((order) => (
                                            <tr key={order._id}>
                                                <td>
                                                    <div className="d-flex gap-3 align-items-center project-name">
                                                        <div className="order-img">
                                                            <img
                                                                src={order.featuredImage || "/assets/img/dashboard/orders/o-1.png"}
                                                                alt=""
                                                                className="rounded-circle"
                                                                style={{ width: '40px', height: '40px' }}
                                                            />
                                                        </div>
                                                        <div>
                                                            <Link to={`/${order.productSlug}/${order.productId}`} className="text-dark-200 project-link">
                                                                {order.packageName}
                                                            </Link>
                                                            <small className="d-block text-muted">
                                                                ID: {order.orderId}
                                                            </small>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="text-dark-200">${order.packagePrice}</td>
                                                <td className="text-dark-200">{order.orderDate}</td>
                                               
                                                <td>
                                                    <span className={getStatusBadgeClass(order.paymentStatus)}>
                                                        {order.paymentStatus}
                                                    </span>
                                                </td>

                                            </tr>
                                        ))}
                                        {orders.length === 0 && (
                                            <tr>
                                                <td colSpan="6" className="text-center py-4">
                                                    No orders found
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                                {orders.length > 9 && (
                                    <div className="d-flex justify-content-center py-4">
                                        <Link className="col-4 w-btn-secondary-lg" to="/seller/orders" style={{ textAlign: "center" }}>All Orders</Link>
                                    </div>
                                )}

                            </div>
                        </div>
                    </div>
                </div>
            </main>

            <style jsx>{`
                .status-badge {
                    padding: 6px 12px;
                    border-radius: 4px;
                    font-size: 14px;
                    font-weight: 500;
                    display: inline-block;
                }
                
                .status-badge.completed {
                    background-color: #e8f5e9;
                    color: #2e7d32;
                }
                
                .status-badge.pending {
                    background-color: #fff3e0;
                    color: #ef6c00;
                }
                
                .status-badge.in-progress {
                    background-color: #e3f2fd;
                    color: #1565c0;
                }
                
                .project-link {
                    color: #333;
                    text-decoration: none;
                    font-weight: 500;
                }
                
                .project-link:hover {
                    color: #007bff;
                }
                
                .dashboard-table thead th {
                    background-color: #f8f9fa;
                    padding: 12px;
                    font-weight: 600;
                }
                .dashboard-table tbody td {
                    padding: 16px 12px;
                    border-bottom: 1px solid #eee;
                }
                .text-dark-200 {
                    color: #6c757d;
                }
                .text-dark-300 {
                    color: #343a40;
                }
            `}</style>
        </>
    );
};

export default MainDashboardContent;