import React, { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import auth from '../../../firebase.init';
import toast from 'react-hot-toast';
import LoadingSpinner from '../../../components/Shared/Loading';
import { Link, useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth';

const SellerDashboardHeader = () => {
    const [user] = useAuthState(auth);
    const [userData, setUserData] = useState(null);

    const navigate = useNavigate();

    const handleSignout = () => {
        signOut(auth).then(() => {
            navigate('/');
        }).catch((error) => {
            console.error('Logout error:', error);
        });
    };


    useEffect(() => {
        if (user?.email) {
            fetch(`https://server.enjoywiki.com/flip-server/users?userEmail=${user.email}`)
                .then((res) => res.json())
                .then((info) => {
                    const matchedUser = info.find(item => item.UserEmail === user.email);
                    if (matchedUser) {
                        setUserData(matchedUser);
                    } else {
                        toast.error("User not found!");
                    }
                })
                .catch(error => {
                    console.error("Error fetching user data:", error);
                    toast.error("Error loading user data");
                });
        }
    }, [user]);
    if (!userData) {
        return <div><LoadingSpinner></LoadingSpinner></div>;
    }
    return (
        <>
            <header className="d-flex dashboard-header justify-content-between align-items-center p-4">
                <div>
                    <button
                        className="d-block d-xl-none text-24"
                        type="button"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasTop"
                        aria-controls="offcanvasTop"
                    >
                        <i className="fa-solid fa-bars" />
                    </button>
                </div>
                <div>
                    <div className="dashboard-header-btns d-flex gap-3">
                        <div className="dropdown">
                            <button
                                className="dropdown-toggle dashboard-header-btn"
                                type="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                            >
                                <img
                                    src={userData.profileURL}
                                    className="rounded-circle"
                                    width={56}
                                    height={56}
                                    alt=""
                                />
                            </button>
                            <ul className="dashboard-profile dropdown-menu">
                                <li>
                                    <Link
                                        className="dashboard-profile-item dropdown-item d-flex gap-2"
                                        to="/seller/dashboard"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width={20}
                                            height={20}
                                            viewBox="0 0 20 20"
                                            fill="none"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M14.1801 20H5.8194C3.51066 20 1.63905 18.214 1.63905 16.0108V11.133C1.63905 10.4248 1.34423 9.74559 0.819444 9.2448C-0.396439 8.0845 -0.238375 6.16205 1.15226 5.19692L7.54099 0.762995C9.00683 -0.254332 10.9927 -0.254332 12.4585 0.762995L18.8472 5.19691C20.2379 6.16205 20.396 8.0845 19.1801 9.2448C18.6553 9.74559 18.3605 10.4248 18.3605 11.133V16.0108C18.3605 18.214 16.4889 20 14.1801 20ZM7.99976 15.25C7.58554 15.25 7.24976 15.5858 7.24976 16C7.24976 16.4142 7.58554 16.75 7.99976 16.75H11.9998C12.414 16.75 12.7498 16.4142 12.7498 16C12.7498 15.5858 12.414 15.25 11.9998 15.25H7.99976Z"
                                                fill="#22BE0D"
                                            />
                                        </svg>
                                        Dashboard
                                    </Link>
                                </li>

                                <li>
                                    <button
                                        className="dashboard-profile-item dropdown-item d-flex gap-2"
                                        onClick={handleSignout}
                                    >
                                        <svg
                                            width={21}
                                            height={21}
                                            viewBox="0 0 21 21"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M12.8331 0H3.49975C2.57235 0.00276977 1.68372 0.372408 1.02794 1.02819C0.372163 1.68396 0.00252563 2.57259 -0.000244141 3.5V12.8333C0.00252563 13.7607 0.372163 14.6494 1.02794 15.3052C1.68372 15.9609 2.57235 16.3306 3.49975 16.3333H12.8331C13.7605 16.3306 14.6491 15.9609 15.3049 15.3052C15.9607 14.6494 16.3303 13.7607 16.3331 12.8333V3.5C16.3303 2.57259 15.9607 1.68396 15.3049 1.02819C14.6491 0.372408 13.7605 0.00276977 12.8331 0ZM10.4997 9.33333H9.33307V10.5C9.33307 10.8094 9.21015 11.1062 8.99136 11.325C8.77257 11.5438 8.47583 11.6667 8.16641 11.6667C7.85699 11.6667 7.56024 11.5438 7.34145 11.325C7.12266 11.1062 6.99974 10.8094 6.99974 10.5V9.33333H5.83308C5.52366 9.33333 5.22691 9.21042 5.00812 8.99163C4.78933 8.77283 4.66641 8.47609 4.66641 8.16667C4.66641 7.85725 4.78933 7.5605 5.00812 7.34171C5.22691 7.12292 5.52366 7 5.83308 7H6.99974V5.83333C6.99974 5.52391 7.12266 5.22717 7.34145 5.00838C7.56024 4.78958 7.85699 4.66667 8.16641 4.66667C8.47583 4.66667 8.77257 4.78958 8.99136 5.00838C9.21015 5.22717 9.33307 5.52391 9.33307 5.83333V7H10.4997C10.8092 7 11.1059 7.12292 11.3247 7.34171C11.5435 7.5605 11.6664 7.85725 11.6664 8.16667C11.6664 8.47609 11.5435 8.77283 11.3247 8.99163C11.1059 9.21042 10.8092 9.33333 10.4997 9.33333Z"
                                                fill="#22BE0D"
                                            />
                                            <path
                                                d="M15.1664 21H4.66642C4.357 21 4.06026 20.8771 3.84146 20.6583C3.62267 20.4395 3.49976 20.1428 3.49976 19.8333C3.49976 19.5239 3.62267 19.2272 3.84146 19.0084C4.06026 18.7896 4.357 18.6667 4.66642 18.6667H15.1664C16.0947 18.6667 16.9849 18.2979 17.6413 17.6415C18.2976 16.9852 18.6664 16.0949 18.6664 15.1667V4.66667C18.6664 4.35725 18.7893 4.0605 19.0081 3.84171C19.2269 3.62292 19.5236 3.5 19.8331 3.5C20.1425 3.5 20.4392 3.62292 20.658 3.84171C20.8768 4.0605 20.9997 4.35725 20.9997 4.66667V15.1667C20.9979 16.7132 20.3827 18.1958 19.2891 19.2894C18.1956 20.383 16.7129 20.9981 15.1664 21Z"
                                                fill="#22BE0D"
                                            />
                                        </svg>
                                        Log Out
                                    </button>
                                </li>

                            </ul>
                        </div>
                    </div>
                </div>
            </header>

        </>
    );
};

export default SellerDashboardHeader;