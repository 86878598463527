import React, { useEffect } from "react";
import {
  useAuthState,
  useSignInWithEmailAndPassword,
  useSignInWithGoogle,
} from "react-firebase-hooks/auth";
import { useForm } from "react-hook-form";
import { Link, useLocation, useNavigate } from "react-router-dom";
import auth from "../firebase.init";
import Loading from "../components/Shared/Loading";
import { Helmet } from "react-helmet";
import PendingProduct from "./PendingProduct";

const getErrorMessage = (errorCode) => {
  switch (errorCode) {
    case 'auth/invalid-email':
      return 'Invalid email address.';
    case 'auth/user-disabled':
      return 'This user has been disabled.';
    case 'auth/user-not-found':
      return 'No user found with this email.';
    case 'auth/wrong-password':
      return 'Incorrect password.';
    case 'auth/email-already-in-use':
      return 'This email is already in use.';
    case 'auth/weak-password':
      return 'Password is too weak.';
    case 'auth/popup-closed-by-user':
      return 'Sign-in popup closed by user.';
    default:
      return 'Incorrect Credentials. Please try again.';
  }
};

const Login = () => {
  const [signInWithGoogle, gUser, gLoading, gError] = useSignInWithGoogle(auth);
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const [signInWithEmailAndPassword, user, loading, error] =
    useSignInWithEmailAndPassword(auth);

  const [userMail] = useAuthState(auth);
  let signInError;
  const navigate = useNavigate();
  const location = useLocation();
  let from = location.state?.from?.pathname || "/";

  // useEffect(() => {
  //   const updateUserData = async () => {
  //     if (!userMail?.email) return;

  //     try {
  //       const response = await fetch(`https://server.enjoywiki.com/flip-server/users/email/${userMail.email}`, {
  //         method: 'PUT',
  //         headers: {
  //           'Content-Type': 'application/json'
  //         },
  //         body: JSON.stringify({
  //           UserEmail: userMail.email,
  //           lastLoginDate: new Date().toISOString()
  //         })
  //       });

  //       if (!response.ok) throw new Error('Failed to update user email');

  //     } catch (error) {
  //       console.error('Error:', error);
  //     }
  //   };

  //   if (userMail) {
  //     updateUserData();
  //     const pendingProductId = localStorage.getItem('pendingProductPurchase');
  //     const shouldRedirectToBuyPage = localStorage.getItem('redirectToBuyPage') === 'true';
  //     localStorage.removeItem('pendingProductPurchase');
  //     localStorage.removeItem('redirectToBuyPage');

  //     if (pendingProductId && shouldRedirectToBuyPage) {
  //       navigate(`/buy/${pendingProductId}`);
  //     } else {
  //       navigate('/');
  //     }
  //   }
  // }, [userMail, navigate]);


  useEffect(() => {
    const updateUserData = async () => {
      if (!userMail?.email) return;

      try {
        const response = await fetch(`https://server.enjoywiki.com/flipBundle-bangladesh-server/users/email/${userMail.email}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            UserEmail: userMail.email,
            lastLoginDate: new Date().toISOString()
          })
        });

        if (!response.ok) throw new Error('Failed to update user email');

      } catch (error) {
        console.error('Error:', error);
      }
    };

    if (userMail) {
      updateUserData();

      // Check for different types of redirects in order of priority
      const commentRedirect = localStorage.getItem('redirectAfterLogin');
      const pendingProductId = localStorage.getItem('pendingProductPurchase');
      const shouldRedirectToBuyPage = localStorage.getItem('redirectToBuyPage') === 'true';

      // Clear all redirect-related items from localStorage
      localStorage.removeItem('redirectAfterLogin');
      localStorage.removeItem('pendingProductPurchase');
      localStorage.removeItem('redirectToBuyPage');

      // Handle redirects in order of priority
      if (commentRedirect) {
        navigate(commentRedirect);
      } else if (pendingProductId && shouldRedirectToBuyPage) {
        navigate(`/buy/${pendingProductId}`);
      } else if (from !== "/") {
        navigate(from);
      } else {
        navigate('/');
      }
    }
  }, [userMail, navigate, from]);

  
  useEffect(() => {
    if (error || gError) {
      window.scrollTo(0, 0);
    }
  }, [error, gError]);

  const onSubmit = (data) => {
    signInWithEmailAndPassword(data.email, data.password);
  };

  if (loading || gLoading) {
    return <Loading />;
  }

  if (error || gError) {
    const errorCode = error?.code || gError?.code;
    signInError = <p className="text-danger text-center"><small>{getErrorMessage(errorCode)}</small></p>;
  }
  return (
    <>
      <Helmet>
        <title>Login | FlipBundle</title>
        <meta name="description" content="Log in to FlipBundle to access your account, manage purchases, and explore digital products." />
      </Helmet>

      <section
        className="w-breadcrumb-area"
        style={{
          backgroundImage:  "url(assets/img/breadcrumb-bg.svg)"
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-auto">
              <div className="position-relative z-2">
                <h2 className="section-title-light mb-2">Sign In</h2>
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb w-breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Sign In
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-110 bg-offWhite">
        <div className="container">
          <PendingProduct />
          <div className="bg-white rounded-3 p-3">
            <div className="row g-4">
              <div className="col-lg-6 p-3 p-lg-5">
                <div className="mb-40">
                  <h2 className="section-title mb-2">Log in</h2>
                  <p className="section-desc">Welcome Back!</p>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="form-container d-flex flex-column gap-4">
                    <div className="form-input">
                      <label htmlFor="email" className="form-label">
                        Email <span className="text-lime-300">*</span>
                      </label>
                      <input
                        type="text"
                        id="email"
                        placeholder="example@gmail.com"
                        className="form-control shadow-none"
                        {...register("email", {
                          required: "Email is Required",
                          pattern: {
                            value: /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/,
                            message: "Provide a valid Email",
                          },
                        })}
                      />
                      {errors.email && (
                        <div className="text-danger">{errors.email.message}</div>
                      )}
                    </div>

                    <div className="form-input">
                      <label htmlFor="password" className="form-label">
                        Password <span className="text-lime-300">*</span>
                      </label>
                      <input
                        type="password"
                        id="password"
                        placeholder="********"
                        className="form-control shadow-none"
                        {...register("password", {
                          required: "Password is Required",
                          minLength: {
                            value: 6,
                            message: "Must be 6 characters or longer",
                          },
                        })}
                      />
                      {errors.password && (
                        <div className="text-danger">{errors.password.message}</div>
                      )}
                    </div>

                    <div className="d-flex align-items-center justify-content-between">
                      <div>
                        <Link to="/reset" className="form-forget-pass">
                          Forget Password
                        </Link>
                      </div>
                    </div>

                    {signInError}

                    <div className="d-grid">
                      <button className="w-btn-secondary-lg">Log In</button>
                    </div>
                  </div>
                </form>

                <div className="py-5">
                  <div className="form-divider d-flex justify-content-center align-items-center">
                    <span className="form-divider-text">OR</span>
                  </div>
                </div>
                <div className="mt-4">
                  <p className="text-center form-text">
                    Don't have an account? <Link to="/register">Create Account</Link>
                  </p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="login-img">
                  <img
                    src="assets/img/login.webp"
                    className="img-fluid w-100"
                    alt="Login illustration"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;