import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import "react-quill/dist/quill.snow.css";
import auth from "../../firebase.init";
import Pagination from "../../components/Shared/Pagination";
import { Helmet } from "react-helmet";
import LoadingAnimations from "../../components/Shared/LoadingAnimations";
import { Edit2, Trash2 } from "lucide-react";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";

const AdminAllComments = () => {
    const [comments, setComments] = useState([]);
    const [user] = useAuthState(auth);
    const [searchTerm, setSearchTerm] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [editingComment, setEditingComment] = useState(null);
    const [editingReplyIndex, setEditingReplyIndex] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);
    const [showReplyModal, setShowReplyModal] = useState(false);
    const [selectedReply, setSelectedReply] = useState(null);

    // Fetch comments
    const fetchComments = async () => {
        try {
            const response = await fetch(`https://server.enjoywiki.com/flip-server/product-comments`);
            if (!response.ok) throw new Error('Failed to fetch comments');
            const data = await response.json();
            setComments(data);
        } catch (error) {
            console.error('Error fetching comments:', error);
            toast.error('Failed to fetch comments');
        }
    };

    useEffect(() => {
        fetchComments();
    }, [user?.email]);

    // Handle Edit Click
    const handleEditClick = (comment) => {
        setEditingComment(comment);
        setEditingReplyIndex(null);
        setShowModal(true);
    };

    // Handle Edit Reply Click
    // Modified handleEditReplyClick
    const handleEditReplyClick = (comment, replyIndex) => {
        setEditingComment(comment);
        setSelectedReply(comment.replies[replyIndex]);
        setEditingReplyIndex(replyIndex);
        setShowReplyModal(true);
    };


    // Handle Update Comment
    const handleUpdateComment = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`https://server.enjoywiki.com/flip-server/update-comment/${editingComment._id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(editingComment),
            });

            if (!response.ok) throw new Error('Failed to update comment');

            setComments(comments.map(c =>
                c._id === editingComment._id ? editingComment : c
            ));
            setShowModal(false);
            setEditingComment(null);
            setEditingReplyIndex(null);
            toast.success('Comment updated successfully');
            fetchComments();
        } catch (error) {
            console.error('Error updating comment:', error);
            toast.error('Error updating comment');
        }
    };

    const handleUpdateReply = async (e) => {
        e.preventDefault();

        // Create a copy of the comment to avoid direct state mutation
        const commentCopy = JSON.parse(JSON.stringify(editingComment));

        // Update the specific reply
        commentCopy.replies[editingReplyIndex] = {
            name: e.target.name.value,
            email: e.target.email.value,
            photo: e.target.photo.value,
            reply: e.target.reply.value,
            timestamp: new Date().toISOString()
        };

        try {
            const response = await fetch(`https://server.enjoywiki.com/flip-server/update-comment/${commentCopy._id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    replies: commentCopy.replies,
                    // Only send necessary fields to update
                    name: commentCopy.name,
                    email: commentCopy.email,
                    photo: commentCopy.photo,
                    comment: commentCopy.comment,
                    timestamp: commentCopy.timestamp,
                    productId: commentCopy.productId
                }),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Failed to update reply');
            }

            // Update local state
            setComments(comments.map(c =>
                c._id === commentCopy._id ? commentCopy : c
            ));

            // Reset states and show success message
            setShowReplyModal(false);
            setEditingComment(null);
            setEditingReplyIndex(null);
            setSelectedReply(null);
            toast.success('Reply updated successfully');

            // Refresh comments
            await fetchComments();

        } catch (error) {
            console.error('Error updating reply:', error);
            toast.error(error.message || 'Error updating reply');
        }
    };

    // Handle Delete Comment
    const handleDeleteComment = async (commentId) => {
        if (!window.confirm('Are you sure you want to delete this comment?')) return;

        try {
            const response = await fetch(`https://server.enjoywiki.com/flip-server/delete-comment/${commentId}`, {
                method: 'DELETE',
            });

            if (!response.ok) throw new Error('Failed to delete comment');

            setComments(comments.filter(comment => comment._id !== commentId));
            toast.success('Comment deleted successfully');
        } catch (error) {
            console.error('Error deleting comment:', error);
            toast.error('Error deleting comment');
        }
    };

    // Handle Delete Reply
    const handleDeleteReply = async (commentId, replyIndex) => {
        if (!window.confirm('Are you sure you want to delete this reply?')) return;

        const updatedComment = {
            ...editingComment,
            replies: editingComment.replies.filter((_, index) => index !== replyIndex)
        };

        try {
            const response = await fetch(`https://server.enjoywiki.com/flip-server/update-comment/${commentId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(updatedComment),
            });

            if (!response.ok) throw new Error('Failed to delete reply');

            setComments(comments.map(c =>
                c._id === commentId ? updatedComment : c
            ));
            toast.success('Reply deleted successfully');
            fetchComments();
        } catch (error) {
            console.error('Error deleting reply:', error);
            toast.error('Error deleting reply');
        }
    };

    // Filter comments based on search term and date range
    const getFilteredComments = () => {
        let filtered = comments;

        if (startDate && endDate) {
            filtered = filtered.filter(comment => {
                const commentDate = new Date(comment.timestamp);
                const start = new Date(startDate);
                const end = new Date(endDate);
                end.setHours(23, 59, 59, 999);
                return commentDate >= start && commentDate <= end;
            });
        }

        if (searchTerm.trim()) {
            const searchLower = searchTerm.toLowerCase();
            filtered = filtered.filter(comment =>
                comment.name?.toLowerCase().includes(searchLower) ||
                comment.email?.toLowerCase().includes(searchLower) ||
                comment.comment?.toLowerCase().includes(searchLower)
            );
        }

        return filtered;
    };

    const filteredComments = getFilteredComments();
    const currentComments = filteredComments.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    // Handle page change
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        setCurrentPage(1);
    }, [searchTerm, startDate, endDate]);

    return (
        <main className="dashboard-main min-vh-100">
            <div className="d-flex flex-column gap-4 pb-110 p-4">
                <Helmet>
                    <title>All Comments | FlipBundle - Manage Product Comments</title>
                    <meta name="description" content="View and manage product comments on FlipBundle. Track customer feedback." />
                </Helmet>

                {/* Header */}
                <div className="d-flex justify-content-between align-items-center">
                    <div>
                        <h3 className="text-24 fw-bold text-dark-300 mb-2">All Comments</h3>
                        <ul className="d-flex align-items-center gap-2">
                            <li className="text-dark-200 fs-6">Admin Dashboard</li>
                            <li>
                                <svg xmlns="http://www.w3.org/2000/svg" width={5} height={11} viewBox="0 0 5 11" fill="none">
                                    <path d="M1 10L4 5.5L1 1" stroke="#5B5B5B" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </li>
                            <li className="text-lime-300 fs-6">All Comments</li>
                        </ul>
                    </div>
                </div>

                {/* Filters */}
                <div className="row mt-4 p-3 bg-light rounded shadow-sm">
                    <div className="col-12 col-md-6 mb-3">
                        <div className="position-relative">
                            <label htmlFor="searchInput" className="form-label small text-muted">Search</label>
                            <input
                                type="text"
                                id="searchInput"
                                className="form-control ps-5"
                                placeholder="Search by name, email or comment..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="col-12 col-md-6 d-flex gap-3">
                        <div className="position-relative w-100">
                            <label htmlFor="startDate" className="form-label small text-muted">Start Date</label>
                            <input
                                type="date"
                                id="startDate"
                                className="form-control"
                                value={startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                            />
                        </div>
                        <div className="position-relative w-100">
                            <label htmlFor="endDate" className="form-label small text-muted">End Date</label>
                            <input
                                type="date"
                                id="endDate"
                                className="form-control"
                                value={endDate}
                                onChange={(e) => setEndDate(e.target.value)}
                            />
                        </div>
                    </div>
                </div>

                {/* Comments Table */}
                <div className="gig-info-card mt-4">
                    <div className="gig-info-body">
                        <div className="overflow-x-auto">
                            <table className="w-100 dashboard-table">
                                <thead>
                                    <tr>
                                        <th scope="col" className="ps-4">User Info</th>
                                        <th scope="col">Product</th>
                                        <th scope="col">Comment</th>
                                        <th scope="col">Date</th>
                                        <th scope="col">Replies</th>
                                        <th scope="col" className="text-center">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {comments === null ? (
                                        <tr>
                                            <td colSpan="6" className="text-center p-4">
                                                <LoadingAnimations type="pulse" color="#2563eb" size="md" />
                                            </td>
                                        </tr>
                                    ) : currentComments.length === 0 ? (
                                        <tr>
                                            <td colSpan="6" className="text-center p-4">
                                                {searchTerm ? "No comments found matching your search." : "No Comments Found!"}
                                            </td>
                                        </tr>
                                    ) : (
                                        currentComments.map((comment) => (
                                            <tr key={comment._id}>
                                                <td>
                                                    <div className="d-flex gap-3 align-items-center">
                                                        {comment.photo && (
                                                            <img
                                                                src={comment.photo}
                                                                alt={comment.name}
                                                                className="rounded"
                                                                style={{ width: '50px', height: '50px', objectFit: 'cover' }}
                                                            />
                                                        )}
                                                        <div>
                                                            <div className="fw-bold">{comment.name}</div>
                                                            <div className="text-muted small">{comment.email}</div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <Link to={`/${comment.productSlug}/${comment.productId}`} className="text-primary text-decoration-none">
                                                        View Product
                                                    </Link>
                                                </td>
                                                <td>
                                                    <div className="text-wrap" style={{ maxWidth: '300px' }}>
                                                        {comment.comment}
                                                    </div>
                                                </td>
                                                <td>
                                                    {new Date(comment.timestamp).toLocaleDateString()}
                                                </td>
                                                <td>
                                                    {comment.replies?.length || 0}
                                                </td>
                                                <td className="text-center">
                                                    <div className="d-flex gap-2 justify-content-center">
                                                        <button
                                                            onClick={() => handleEditClick(comment)}
                                                            className="btn btn-primary btn-sm"
                                                        >
                                                            <Edit2 size={15} />
                                                        </button>
                                                        <button
                                                            onClick={() => handleDeleteComment(comment._id)}
                                                            className="btn btn-danger btn-sm"
                                                        >
                                                            <Trash2 size={15} />
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                    )}
                                </tbody>
                            </table>
                        </div>

                        {/* Pagination */}
                        {filteredComments.length > itemsPerPage && (
                            <div className="pagination-container mt-4 d-flex justify-content-center">
                                <Pagination
                                    currentPage={currentPage}
                                    totalPages={Math.ceil(filteredComments.length / itemsPerPage)}
                                    onPageChange={handlePageChange}
                                />
                            </div>
                        )}
                    </div>
                </div>

                {/* Edit Modal */}
                {showModal && (
                    <>
                        <div className="modal show d-block" tabIndex="-1">
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title">
                                            {editingReplyIndex !== null ? 'Edit Reply' : 'Edit Comment'}
                                        </h5>
                                        <button
                                            type="button"
                                            className="btn-close"
                                            onClick={() => {
                                                setShowModal(false);
                                                setEditingComment(null);
                                                setEditingReplyIndex(null);
                                            }}
                                        ></button>
                                    </div>
                                    <form onSubmit={editingReplyIndex !== null ? handleUpdateReply : handleUpdateComment}>
                                        <div className="modal-body">
                                            {editingReplyIndex === null ? (
                                                // Comment Edit Form
                                                <>
                                                    <div className="mb-3">
                                                        <label className="form-label">Name</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            value={editingComment?.name || ''}
                                                            onChange={(e) => setEditingComment({
                                                                ...editingComment,
                                                                name: e.target.value
                                                            })}
                                                            required
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <label className="form-label">Email</label>
                                                        <input
                                                            type="email"
                                                            className="form-control"
                                                            value={editingComment?.email || ''}
                                                            onChange={(e) => setEditingComment({
                                                                ...editingComment,
                                                                email: e.target.value
                                                            })}
                                                            required
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <label className="form-label">Photo URL</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            value={editingComment?.photo || ''}
                                                            onChange={(e) => setEditingComment({
                                                                ...editingComment,
                                                                photo: e.target.value
                                                            })}
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <label className="form-label">Comment</label>
                                                        <textarea
                                                            className="form-control"
                                                            rows="4"
                                                            value={editingComment?.comment || ''}
                                                            onChange={(e) => setEditingComment({
                                                                ...editingComment,
                                                                comment: e.target.value
                                                            })}
                                                            required
                                                        ></textarea>
                                                    </div>

                                                    {/* Replies List */}
                                                    {editingComment?.replies?.length > 0 && (
                                                        <div className="mb-3">
                                                            <label className="form-label">Replies</label>
                                                            <div className="border rounded p-3" style={{ maxHeight: '300px', overflowY: 'auto' }}>
                                                                {editingComment.replies.map((reply, index) => (
                                                                    <div key={index} className="border-bottom mb-2 pb-2">
                                                                        <div className="d-flex justify-content-between align-items-start">
                                                                            <div className="d-flex gap-2">
                                                                                {reply.photo && (
                                                                                    <img
                                                                                        src={reply.photo}
                                                                                        alt={reply.name}
                                                                                        className="rounded-circle"
                                                                                        style={{ width: '30px', height: '30px' }}
                                                                                    />
                                                                                )}
                                                                                <div>
                                                                                    <div className="fw-bold">{reply.name}</div>
                                                                                    <div className="text-muted small">{reply.email}</div>
                                                                                    <div className="mt-1">{reply.reply}</div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="d-flex gap-2">
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-sm btn-outline-primary"
                                                                                    onClick={() => handleEditReplyClick(editingComment, index)}
                                                                                >
                                                                                    <Edit2 size={14} />
                                                                                </button>
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-sm btn-outline-danger"
                                                                                    onClick={() => handleDeleteReply(editingComment._id, index)}
                                                                                >
                                                                                    <Trash2 size={14} />
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    )}
                                                </>
                                            ) : (
                                                // Reply Edit Form
                                                <>
                                                    <div className="mb-3">
                                                        <label className="form-label">Name</label>
                                                        <input
                                                            type="text"
                                                            name="name"
                                                            className="form-control"
                                                            defaultValue={editingComment?.replies[editingReplyIndex]?.name}
                                                            required
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <label className="form-label">Email</label>
                                                        <input
                                                            type="email"
                                                            name="email"
                                                            className="form-control"
                                                            defaultValue={editingComment?.replies[editingReplyIndex]?.email}
                                                            required
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <label className="form-label">Photo URL</label>
                                                        <input
                                                            type="text"
                                                            name="photo"
                                                            className="form-control"
                                                            defaultValue={editingComment?.replies[editingReplyIndex]?.photo}
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <label className="form-label">Reply</label>
                                                        <textarea
                                                            name="reply"
                                                            className="form-control"
                                                            rows="4"
                                                            defaultValue={editingComment?.replies[editingReplyIndex]?.reply}
                                                            required
                                                        ></textarea>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                        <div className="modal-footer">
                                            <button
                                                type="button"
                                                className="btn btn-secondary"
                                                onClick={() => {
                                                    setShowModal(false);
                                                    setEditingComment(null);
                                                    setEditingReplyIndex(null);
                                                }}
                                            >
                                                Cancel
                                            </button>
                                            <button type="submit" className="btn btn-primary">
                                                Save Changes
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="modal-backdrop show"></div>
                    </>
                )}
                {showReplyModal && (
                    <>
                        <div className="modal show d-block" tabIndex="-1">
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title">Edit Reply</h5>
                                        <button
                                            type="button"
                                            className="btn-close"
                                            onClick={() => {
                                                setShowReplyModal(false);
                                                setEditingComment(null);
                                                setEditingReplyIndex(null);
                                                setSelectedReply(null);
                                            }}
                                        ></button>
                                    </div>
                                    <form onSubmit={handleUpdateReply}>
                                        <div className="modal-body">
                                            <div className="mb-3">
                                                <label className="form-label">Name</label>
                                                <input
                                                    type="text"
                                                    name="name"
                                                    className="form-control"
                                                    defaultValue={selectedReply?.name || ''}
                                                    required
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label">Email</label>
                                                <input
                                                    type="email"
                                                    name="email"
                                                    className="form-control"
                                                    defaultValue={selectedReply?.email || ''}
                                                    required
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label">Photo URL</label>
                                                <input
                                                    type="text"
                                                    name="photo"
                                                    className="form-control"
                                                    defaultValue={selectedReply?.photo || ''}
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label">Reply</label>
                                                <textarea
                                                    name="reply"
                                                    className="form-control"
                                                    rows="4"
                                                    defaultValue={selectedReply?.reply || ''}
                                                    required
                                                ></textarea>
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <button
                                                type="button"
                                                className="btn btn-secondary"
                                                onClick={() => {
                                                    setShowReplyModal(false);
                                                    setEditingComment(null);
                                                    setEditingReplyIndex(null);
                                                    setSelectedReply(null);
                                                }}
                                            >
                                                Cancel
                                            </button>
                                            <button type="submit" className="btn btn-primary">
                                                Save Changes
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="modal-backdrop show"></div>
                    </>
                )}
            </div>
        </main>
    );
};

export default AdminAllComments;