import { signOut } from "firebase/auth";
import { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link, useNavigate } from "react-router-dom";
import auth from "../../firebase.init";
import { PackagePlus, ShoppingBag, User, UserPlus } from 'lucide-react';
import TopCategoriesNav from "./TopCategoriesNav";
import { CategoriesNavItem, MobileCategoriesNavItem } from "./CategoriesModal";


const NavBar = () => {
  const [logo, setLogo] = useState({});
  const [user] = useAuthState(auth);
  const [userInfo, setUserInfo] = useState([]);

  const handleSignout = () => {
    signOut(auth).then(() => {
      navigate('/');
    }).catch((error) => {
      console.error('Logout error:', error);
    });
  };

  const [searchValue, setSearchValue] = useState("");
  const navigate = useNavigate();

  const handleSearch = (e) => {
    e.preventDefault();
    if (searchValue.trim()) {
      navigate(`/search-products/?query=${encodeURIComponent(searchValue.trim())}`);
    }
  };
  useEffect(() => {
    fetch(`https://server.enjoywiki.com/flip-server/logo`)
      .then((res) => res.json())
      .then((info) => setLogo(info[0]));
  }, []);

  useEffect(() => {
    fetch(`https://server.enjoywiki.com/flip-server/user-data?UserEmail=${user?.email}`)
      .then((res) => res.json())
      .then((data) => setUserInfo(data));
  }, [user?.email]);

  const [products, setProducts] = useState([]);

  useEffect(() => {
    fetch(`https://server.enjoywiki.com/flip-server/products`)
      .then((res) => res.json())
      .then((info) => setProducts(info.reverse()));
  }, []);

  const [categories, setCategories] = useState([]);
  useEffect(() => {
    fetch(`https://server.enjoywiki.com/flip-server/categories`)
      .then((res) => res.json())
      .then((info) => setCategories(info));
  }, []);
  const uniqueCategories = [
    ...new Set(categories.map((category) => JSON.stringify(category))),
  ].map((item) => JSON.parse(item));

  const [hideUserDashLink, setHideUserDashLink] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setHideUserDashLink(window.innerWidth >= 992);
    };
    // Initial check on component mount
    handleResize();
    // Add event listener for window resize
    window.addEventListener("resize", handleResize);
    // Cleanup on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const defaultProfileImage =
    "https://static.vecteezy.com/system/resources/previews/002/002/403/non_2x/man-with-beard-avatar-character-isolated-icon-free-vector.jpg";

  useEffect(() => {
    fetch(`https://server.enjoywiki.com/flip-server/categories`)
      .then((res) => res.json())
      .then((info) => setCategories(info));
  }, []);


  return (
    <>
      <header className="header-primary">
        <div className="container">
          <nav className="navbar navbar-expand-xl justify-content-between">
            <Link to="/">
              <img src="/assets/img/logo/FlipBundle_main_Logo.png" width={200} alt="" />
            </Link>
            <div className="collapse navbar-collapse" id="navbarNav">
              <form className="col-12 m-lg-4" onSubmit={handleSearch}>
                <div className="hero-form-wrapper bg-white d-flex position-relative" style={{ height: 60 }}>
                  <div>
                    <input
                      type="text"
                      className="form-control shadow-none"
                      placeholder="Search for any product..."
                      value={searchValue}
                      onChange={(e) => setSearchValue(e.target.value)}
                    />
                    <button type="submit" className="hero-form-btn position-absolute" style={{ padding: "14px 20px" }}>
                      <svg
                        width={19}
                        height={18}
                        viewBox="0 0 19 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15Z"
                          stroke="#F2F2F2"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M18 17L14 13"
                          stroke="#F2F2F2"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>

                    </button>
                  </div>
                </div>
              </form>
              <ul>
                <li className="d-block d-xl-none mt-4">
                  {/* all Products */}
                  <a href="/products" style={{
                    color: '#22be0d',
                    backgroundColor: '#ffffff00',
                    padding: '10px 22px'
                  }}
                  >
                    <ShoppingBag />
                    All Products
                  </a>
                </li>


                <li className="nav-item mt-2">
                  <div className="d-block d-lg-none">
                    <MobileCategoriesNavItem categories={uniqueCategories} />
                  </div>
                </li>
              </ul>
              <div className="d-flex align-items-center gap-4 mt-4">
                <div className="d-flex d-lg-none">
                  {user ? (
                    <>
                      {userInfo
                        .filter((profile) => profile.UserEmail === user.email)
                        .map((profile) => (
                          <div key={profile.UserEmail} className="d-flex flex-column gap-2">
                            {profile.userRole === "Seller" && (
                              <a
                                href="/seller/dashboard"
                                className="header-btn"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={13}
                                  height={17}
                                  viewBox="0 0 13 17"
                                  fill="none"
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M6.5 7.55556C8.55134 7.55556 10.2143 5.86419 10.2143 3.77778C10.2143 1.69137 8.55134 0 6.5 0C4.44866 0 2.78571 1.69137 2.78571 3.77778C2.78571 5.86419 4.44866 7.55556 6.5 7.55556ZM6.5 17C10.0899 17 13 15.3086 13 13.2222C13 11.1358 10.0899 9.44444 6.5 9.44444C2.91015 9.44444 0 11.1358 0 13.2222C0 15.3086 2.91015 17 6.5 17Z"
                                    fill="white"
                                  />
                                </svg>
                                Seller Dashboard
                              </a>
                            )}
                            {profile.userRole === "Buyer" && (
                              <a
                                href="/buyer/dashboard"
                                className="header-btn"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={13}
                                  height={17}
                                  viewBox="0 0 13 17"
                                  fill="none"
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M6.5 7.55556C8.55134 7.55556 10.2143 5.86419 10.2143 3.77778C10.2143 1.69137 8.55134 0 6.5 0C4.44866 0 2.78571 1.69137 2.78571 3.77778C2.78571 5.86419 4.44866 7.55556 6.5 7.55556ZM6.5 17C10.0899 17 13 15.3086 13 13.2222C13 11.1358 10.0899 9.44444 6.5 9.44444C2.91015 9.44444 0 11.1358 0 13.2222C0 15.3086 2.91015 17 6.5 17Z"
                                    fill="white"
                                  />
                                </svg>
                                Buyer Dashboard
                              </a>
                            )}
                            {profile.userRole === "Admin" && (
                              <a
                                href="/admin/dashboard"
                                className="header-btn"
                                target="_blank"
                                rel="noreferrer"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={13}
                                  height={17}
                                  viewBox="0 0 13 17"
                                  fill="none"
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M6.5 7.55556C8.55134 7.55556 10.2143 5.86419 10.2143 3.77778C10.2143 1.69137 8.55134 0 6.5 0C4.44866 0 2.78571 1.69137 2.78571 3.77778C2.78571 5.86419 4.44866 7.55556 6.5 7.55556ZM6.5 17C10.0899 17 13 15.3086 13 13.2222C13 11.1358 10.0899 9.44444 6.5 9.44444C2.91015 9.44444 0 11.1358 0 13.2222C0 15.3086 2.91015 17 6.5 17Z"
                                    fill="white"
                                  />
                                </svg>
                                Admin Dashboard
                              </a>
                            )}
                            <button
                              onClick={handleSignout}
                              className="header-btn"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={13}
                                height={17}
                                viewBox="0 0 13 17"
                                fill="none"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M6.5 7.55556C8.55134 7.55556 10.2143 5.86419 10.2143 3.77778C10.2143 1.69137 8.55134 0 6.5 0C4.44866 0 2.78571 1.69137 2.78571 3.77778C2.78571 5.86419 4.44866 7.55556 6.5 7.55556ZM6.5 17C10.0899 17 13 15.3086 13 13.2222C13 11.1358 10.0899 9.44444 6.5 9.44444C2.91015 9.44444 0 11.1358 0 13.2222C0 15.3086 2.91015 17 6.5 17Z"
                                  fill="white"
                                />
                              </svg>
                              Logout
                            </button>
                          </div>
                        ))}
                    </>
                  ) : (
                    <Link to="/login" className="header-btn">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={13}
                        height={17}
                        viewBox="0 0 13 17"
                        fill="none"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M6.5 7.55556C8.55134 7.55556 10.2143 5.86419 10.2143 3.77778C10.2143 1.69137 8.55134 0 6.5 0C4.44866 0 2.78571 1.69137 2.78571 3.77778C2.78571 5.86419 4.44866 7.55556 6.5 7.55556ZM6.5 17C10.0899 17 13 15.3086 13 13.2222C13 11.1358 10.0899 9.44444 6.5 9.44444C2.91015 9.44444 0 11.1358 0 13.2222C0 15.3086 2.91015 17 6.5 17Z"
                          fill="white"
                        />
                      </svg>
                      Login
                    </Link>
                  )}
                </div>
              </div>
            </div>
            <div className="navbar-right d-flex align-items-center gap-4">
              <div className="align-items-center d-none d-lg-flex">
                <div className="d-flex">
                  <Link to="/products" className="w-btn-secondary-lg" style={{
                    color: '#22be0d',
                    backgroundColor: '#ffffff00',
                    padding: '10px 22px'
                  }}
                  >
                    <ShoppingBag />
                    All Products
                  </Link>
                </div>
                {user ? (
                  <>
                    <div className="dropdown">
                      <button
                        className="dropdown-toggle custom-dropdown-btn"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        {userInfo
                          .filter(
                            (profile) => profile.UserEmail === user.email
                          )
                          .map((profile) => (
                            <img
                              src={
                                profile.profileURL
                                  ? profile.profileURL
                                  : defaultProfileImage
                              }
                              width={56}
                              height={56}
                              alt="img"
                              style={{
                                width: "40px",
                                height: "40px",
                                objectFit: "cover",
                                borderRadius: "50%",
                              }}
                            />
                          ))}
                      </button>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                      >
                        {user && user.email && userInfo.length > 0 && (
                          <>
                            {userInfo.map((profile) => {
                              if (profile.UserEmail === user?.email) {
                                return (
                                  < >
                                    {profile.userRole === "Seller" && (
                                      <a
                                        className="dropdown-item text-black"
                                        href="/seller/dashboard"
                                        style={{ color: "black" }}
                                      >
                                        Dashboard
                                      </a>
                                    )}
                                    {profile.userRole === "Seller" && (
                                      <Link
                                        className="dropdown-item text-black"
                                        to="/sell-now"
                                        style={{ color: "black" }}
                                      >
                                        Sell Now
                                      </Link>
                                    )}

                                    {profile.userRole === "Buyer" && (
                                      <a
                                        className="dropdown-item text-black"
                                        href="/buyer/dashboard"
                                        style={{ color: "black" }}
                                      >
                                        <span>Dashboard</span>
                                      </a>
                                    )}
                                    {profile.userRole === "Admin" && (
                                      <a
                                        className="dropdown-item text-black"
                                        target="_blank"
                                        rel="noreferrer"
                                        href="/admin/dashboard"
                                        style={{ color: "black" }}
                                      >
                                        <span>Admin Dashboard</span>
                                      </a>
                                    )}
                                  </>
                                );
                              }
                              return null;
                            })}
                          </>
                        )}
                        <div
                          className="dropdown-item"
                          onClick={handleSignout}
                          style={{ color: "black", cursor: "pointer" }}
                        >
                          Logout
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="d-flex">
                    <Link to="/login" className="w-btn-secondary-lg" style={{
                      color: '#22be0d',
                      backgroundColor: '#ffffff00',
                      padding: '10px 22px'
                    }}
                    >
                      <User />
                      Login
                    </Link>
                    <Link to="/register" className="w-btn-secondary-lg" style={{
                      color: '#22be0d',
                      backgroundColor: '#ffffff00',
                      padding: '10px 22px'
                    }}
                    >
                      <UserPlus />
                      Signup
                    </Link>
                    {
                      user ?
                        <>{userInfo.map((profile) => {
                          if (profile.UserEmail === user?.email) {
                            return (
                              <>
                                {profile.userRole === "Buyer" ? (
                                  <></>
                                ) : (
                                  <div class="d-none d-lg-block">
                                    <Link to="/sell-now" class="w-btn-secondary-lg">
                                      <PackagePlus />
                                      Sell Now
                                    </Link>
                                  </div>
                                )}
                              </>
                            );
                          }
                          return null;
                        })}</>

                        :
                        <div class="d-none d-lg-block">
                          <Link to="/sell-now" class="w-btn-secondary-lg">
                            <PackagePlus />
                            Sell Now
                          </Link>
                        </div>
                    }
                  </div>
                )}
              </div>
              <button
                className="navbar-toggler d-block d-xl-none"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span />
              </button>
            </div>
          </nav>
        </div>
      </header>
      <TopCategoriesNav />
      <>
        {/* Category Modal */}
        <div
          className="modal fade categor"
          id="exampleModal"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-xl">
            <div className="modal-content">
              <div className="modal-header px-5 py-4 d-flex justify-content-between items-placeholder border-bottom">
                <div>
                  <h3 className="text-dark-300 fw-bold text-24">All Categories</h3>
                </div>
                <div>
                  <button type="button" data-bs-dismiss="modal" aria-label="Close">
                    <svg
                      width={32}
                      height={32}
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx={16} cy={16} r={16} fill="#FF3838" />
                      <path
                        d="M22.2188 9.77759L8.88614 23.1109"
                        stroke="white"
                        strokeWidth="1.8"
                        strokeLinecap="round"
                      />
                      <path
                        d="M22.2188 23.1099L8.88614 9.77654"
                        stroke="white"
                        strokeWidth="1.8"
                        strokeLinecap="round"
                      />
                    </svg>
                  </button>
                </div>
              </div>
              <div className="modal-body px-5 py-4">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="row">
                      <div className="col-lg-3 mb-4">
                        <div>
                          <h4 className="text-18 fw-semibold text-dark-300 mb-2">
                            Graphic
                          </h4>
                          {uniqueCategories.map(
                            (category) =>
                              category.mainCategory === "Graphic" && (
                                <>
                                  <nav className="category-nav">
                                    <ul>
                                      <li key={category.slug}>
                                        <a href={`/category/${category.slug}`}>
                                          {category.categoryName}
                                        </a>
                                      </li>
                                    </ul>
                                  </nav>
                                </>
                              )
                          )}
                        </div>
                      </div>
                      <div className="col-lg-3 mb-4">
                        <div>
                          <h4 className="text-18 fw-semibold text-dark-300 mb-2">
                            Templates
                          </h4>
                          {uniqueCategories.map(
                            (category) =>
                              category.mainCategory === "Template" && (
                                <>
                                  <nav className="category-nav">
                                    <ul>
                                      <li key={category.slug}>
                                        <a href={`/category/${category.slug}`}>
                                          {category.categoryName}
                                        </a>
                                      </li>
                                    </ul>
                                  </nav>
                                </>
                              )
                          )}
                        </div>
                      </div>
                      <div className="col-lg-3 mb-4">
                        <div>
                          <h4 className="text-18 fw-semibold text-dark-300 mb-2">
                            Database
                          </h4>
                          {uniqueCategories.map(
                            (category) =>
                              category.mainCategory === "Database" && (
                                <>
                                  <nav className="category-nav">
                                    <ul>
                                      <li key={category.slug}>
                                        <a href={`/category/${category.slug}`}>
                                          {category.categoryName}
                                        </a>
                                      </li>
                                    </ul>
                                  </nav>
                                </>
                              )
                          )}
                        </div>
                      </div>
                      <div className="col-lg-3 mb-4 mb-lg-0">
                        <div>
                          <h4 className="text-18 fw-semibold text-dark-300 mb-2">
                            Website
                          </h4>
                          {uniqueCategories.map(
                            (category) =>
                              category.mainCategory === "Website" && (
                                <>
                                  <nav className="category-nav">
                                    <ul>
                                      <li key={category.slug}>
                                        <a href={`/category/${category.slug}`}>
                                          {category.categoryName}
                                        </a>
                                      </li>
                                    </ul>
                                  </nav>
                                </>
                              )
                          )}
                        </div>
                      </div>
                      <div className="col-lg-3 mb-4 mb-lg-0">
                        <div>
                          <h4 className="text-18 fw-semibold text-dark-300 mb-2">
                            E-Book
                          </h4>
                          {uniqueCategories.map(
                            (category) =>
                              category.mainCategory === "eBook" && (
                                <>
                                  <nav className="category-nav">
                                    <ul>
                                      <li key={category.slug}>
                                        <a href={`/category/${category.slug}`}>
                                          {category.categoryName}
                                        </a>
                                      </li>
                                    </ul>
                                  </nav>
                                </>
                              )
                          )}
                        </div>
                      </div>
                      <div className="col-lg-3 mb-4 mb-lg-0">
                        <div>
                          <h4 className="text-18 fw-semibold text-dark-300 mb-2">
                            Photos
                          </h4>
                          {uniqueCategories.map(
                            (category) =>
                              category.mainCategory === "StockPhotos" && (
                                <>
                                  <nav className="category-nav">
                                    <ul>
                                      <li key={category.slug}>
                                        <a href={`/category/${category.slug}`}>
                                          {category.categoryName}
                                        </a>
                                      </li>
                                    </ul>
                                  </nav>
                                </>
                              )
                          )}
                        </div>
                      </div>
                      <div className="col-lg-3 mb-4 mb-lg-0">
                        <div>
                          <h4 className="text-18 fw-semibold text-dark-300 mb-2">
                            Videos
                          </h4>
                          {uniqueCategories.map(
                            (category) =>
                              category.mainCategory === "StockVideos" && (
                                <>
                                  <nav className="category-nav">
                                    <ul>
                                      <li key={category.slug}>
                                        <a href={`/category/${category.slug}`}>
                                          {category.categoryName}
                                        </a>
                                      </li>
                                    </ul>
                                  </nav>
                                </>
                              )
                          )}
                        </div>
                      </div>
                      <div className="col-lg-3 mb-4 mb-lg-0">
                        <div>
                          <h4 className="text-18 fw-semibold text-dark-300 mb-2">
                            Software
                          </h4>
                          {uniqueCategories.map(
                            (category) =>
                              category.mainCategory === "Software" && (
                                <>
                                  <nav className="category-nav">
                                    <ul>
                                      <li key={category.slug}>
                                        <a href={`/category/${category.slug}`}>
                                          {category.categoryName}
                                        </a>
                                      </li>
                                    </ul>
                                  </nav>
                                </>
                              )
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Modal End */}
      </>
    </>
  );
};

export default NavBar;
