// import React, { useState, useEffect } from 'react';
// import { MessageCircleMore } from 'lucide-react';

// const CommentCount = ({ productId }) => {
//     const [totalComments, setTotalComments] = useState(0);

//     useEffect(() => {
//         const fetchCommentCount = async () => {
//             try {
//                 const response = await fetch(`https://server.enjoywiki.com/flip-server/product-comment/${productId}`);
//                 const comments = await response.json();
//                 const productComments = comments.filter(comment => comment.productId === productId);

//                 // Count main comments and their replies
//                 const total = productComments.reduce((acc, comment) => {
//                     const replyCount = comment.replies ? comment.replies.length : 0;
//                     return acc + 1 + replyCount; // 1 for the main comment plus replies
//                 }, 0);

//                 setTotalComments(total);
//             } catch (error) {
//                 console.error("Error fetching comments:", error);
//             }
//         };

//         if (productId) {
//             fetchCommentCount();
//         }
//     }, [productId]);

//     const scrollToComments = () => {
//         const commentSection = document.getElementById('comment-section');
//         if (commentSection) {
//             commentSection.scrollIntoView({
//                 behavior: 'smooth',
//                 block: 'start'
//             });
//         }
//     };

//     return (
//         <button
//             onClick={scrollToComments}
//             className="bg-transparent border-0 p-0 cursor-pointer hover:text-green-600 transition-colors duration-300 flex items-center gap-2"
//         >
//             <p className="fw-semibold text-dark-300 text-18 text-dark-200 mb-0 flex items-center gap-2">
//                 <MessageCircleMore />
//                 {totalComments} {totalComments < 1 ? 'comment' : 'comments'}
//             </p>
//         </button>
//     );
// };

// export default CommentCount;



import React, { useState, useEffect } from 'react';
import { MessageCircleMore } from 'lucide-react';

const CommentCount = ({ productId }) => {
    const [totalComments, setTotalComments] = useState(0);

    useEffect(() => {
        const fetchCommentCount = async () => {
            try {
                const response = await fetch(`https://server.enjoywiki.com/flip-server/product-comment/${productId}`);
                const comments = await response.json();
                const productComments = comments.filter(comment => comment.productId === productId);

                // Count main comments and their replies
                const total = productComments.reduce((acc, comment) => {
                    const replyCount = comment.replies ? comment.replies.length : 0;
                    return acc + 1 + replyCount; // 1 for the main comment plus replies
                }, 0);

                setTotalComments(total);
            } catch (error) {
                console.error("Error fetching comments:", error);
            }
        };

        if (productId) {
            fetchCommentCount();
        }
    }, [productId]);

    const scrollToComments = (e) => {
        e.preventDefault();
        const commentsSection = document.getElementById('comments-section');
        if (commentsSection) {
            const offset = 80; // Adjust this value based on your header height
            const elementPosition = commentsSection.getBoundingClientRect().top;
            const offsetPosition = elementPosition + window.pageYOffset - offset;
            
            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth'
            });
        }
    };

    return (
        <button
            onClick={scrollToComments}
            className="bg-transparent border-0 p-0 cursor-pointer hover:text-green-600 transition-colors duration-300 flex items-center gap-2"
        >
            <p className="fw-semibold text-dark-300 text-18 text-dark-200 mb-0 flex items-center gap-2">
                <MessageCircleMore />
                {totalComments} {totalComments === 1 ? 'comment' : 'comments'}
            </p>
        </button>
    );
};

export default CommentCount;