import React, { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Reply, Loader } from 'lucide-react';
import auth from '../../firebase.init';
import toast from 'react-hot-toast';
import { Link, useLocation, useNavigate } from 'react-router-dom';

const ProductComment = ({ productId, sellerEmail, productSlug }) => {
    const [user] = useAuthState(auth);
    const [userData, setUserData] = useState(null);
    const [comments, setComments] = useState([]);
    const [replyingTo, setReplyingTo] = useState(null);
    const location = useLocation();
    const navigate = useNavigate();

    const [isPostingComment, setIsPostingComment] = useState(false);
    const [isPostingReply, setIsPostingReply] = useState(false);

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        comment: ''
    });
    const isSeller = user?.email === sellerEmail;

    const handleLoginClick = () => {
        localStorage.setItem('redirectAfterLogin', location.pathname);
        navigate('/login');
    };

    const [replyText, setReplyText] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    const fetchComments = async () => {
        try {
            const response = await fetch(`https://server.enjoywiki.com/flip-server/product-comment/${productId}`);
            const data = await response.json();
            const productComments = data.filter(comment => comment.productId === productId);
            setComments(productComments);
        } catch (error) {
            console.error("Error fetching comments:", error);
            toast.error("Error loading comments");
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchComments();
    }, [productId]);

    useEffect(() => {
        if (user?.email) {
            fetch(`https://server.enjoywiki.com/flip-server/api/user-profile?email=${user.email}`)
                .then((res) => res.json())
                .then((info) => {
                    setUserData(info);
                })
                .catch(error => {
                    console.error("Error fetching user data:", error);
                    toast.error("Error loading user data");
                });
        }
    }, [user]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!user) {
            toast.error('Please login to comment');
            return;
        }

        if (!formData.comment.trim()) {
            toast.error('Comment cannot be empty');
            return;
        }

        setIsPostingComment(true);

        try {
            const response = await fetch('https://server.enjoywiki.com/flip-server/add-product-comment', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    productId: productId,
                    productSlug: productSlug,
                    name: userData.userName,
                    photo: userData.profileURL,
                    email: userData.userEmail,
                    comment: formData.comment,
                    timestamp: new Date().toISOString(),
                    sellerEmail
                }),
            });

            if (response.ok) {
                toast.success('Comment added successfully!');
                setFormData(prev => ({
                    ...prev,
                    comment: ''
                }));
                fetchComments();
            } else {
                toast.error('Failed to add comment');
            }
        } catch (error) {
            console.error('Error posting comment:', error);
            toast.error('Error posting comment');
        } finally {
            setIsPostingComment(false);
        }
    };
    const handleReplyClick = (commentId, userName) => {
        setReplyingTo(commentId);
        setReplyText(`@${userName} `);
    };

    const handleReply = async (commentId) => {
        if (!user) {
            toast.error('Please login to reply');
            return;
        }

        if (!replyText.trim()) {
            toast.error('Reply cannot be empty');
            return;
        }

        setIsPostingReply(true);

        try {
            const response = await fetch('https://server.enjoywiki.com/flip-server/add-comment-reply', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    productId,
                    commentId,
                    name: userData.userName,
                    email: userData.userEmail,
                    photo: userData.profileURL,
                    reply: replyText,
                    timestamp: new Date().toISOString(),
                    isSeller,
                    recipientEmail: comments.find(c => c._id === commentId)?.email
                }),
            });

            if (response.ok) {
                toast.success('Reply added successfully!');
                setReplyText('');
                setReplyingTo(null);
                fetchComments();
            } else {
                toast.error('Failed to add reply');
            }
        } catch (error) {
            console.error('Error posting reply:', error);
            toast.error('Error posting reply');
        } finally {
            setIsPostingReply(false);
        }
    };

    if (isLoading) {
        return (
            <div className="text-center py-4">
                <Loader className="w-6 h-6 animate-spin" />
                <p className="mt-2">Loading comments...</p>
            </div>
        );
    }

    return (
        <div id="comments-section">
            <h3 className="service-details-title text-dark-200 fw-bold mb-30 mt-30">Comments</h3>

            {user ? (
                <div className="bg-white comment-form mb-4">
                    <h3 className="fw-bold text-dark-300 text-18 mb-4">Write a Comment</h3>
                    <form onSubmit={handleSubmit}>
                        <div className="row g-4">
                            <div className="col-12">
                                <div className="comment-form-input">
                                    <label htmlFor="desc" className="fw-normal fs-6 text-dark-300 d-block mb-1">
                                        Comment
                                    </label>
                                    <textarea
                                        type="text"
                                        className="form-control form-textarea shadow-none"
                                        id="desc"
                                        placeholder="Write your comment here..."
                                        name="comment"
                                        value={formData.comment}
                                        onChange={handleInputChange}
                                        required
                                        disabled={isPostingComment}
                                    />
                                </div>
                            </div>
                            <div className="col-auto">
                                <button
                                    type="submit"
                                    className="comment-form-btn"
                                    disabled={isPostingComment}
                                >
                                    {isPostingComment ? (
                                        <>
                                            <Loader className="w-4 h-4 me-2 animate-spin" />
                                            Posting...
                                        </>
                                    ) : (
                                        'Submit Now'
                                    )}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            ) : (
                <div className="bg-white p-4 mb-4 text-center">
                    <p className="mb-3">Please login to leave a comment</p>
                    <button onClick={handleLoginClick} className="btn btn-primary">
                        Login
                    </button>
                </div>
            )}

            <div className="bg-white blog-single-comment gap-4">
                {comments.length === 0 ? (
                    <p className="text-center py-4">No comments yet. Be the first to comment!</p>
                ) : (
                    comments.map((comment) => (
                        <div key={comment._id} className="comment-container mb-4 p-4 border rounded">
                            <div className="d-flex gap-3">
                                <div>
                                    <img
                                        src={comment.photo || 'https://raw.githubusercontent.com/Shah-Limon/em-list/master/images-dash/profile.jpg'}
                                        className="rounded-circle"
                                        alt=""
                                        width={50}
                                        height={50}
                                    />
                                </div>
                                <div className="flex-grow-1">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <h4 className="text-18 fw-semibold text-dark-300">{comment.name}</h4>
                                    </div>
                                    <p className="py-2 text-dark-200 fs-6">{comment.comment}</p>
                                    {(user && (isSeller || user.email === comment.email)) && (
                                        <button
                                            className="btn btn-link text-primary p-0"
                                            onClick={() => handleReplyClick(comment._id, comment.name)}
                                            disabled={isPostingReply}
                                        >
                                            <Reply className="w-4 h-4 me-1" />
                                            Reply
                                        </button>
                                    )}
                                </div>
                            </div>

                            {replyingTo === comment._id && user && (
                                <div className="ms-5 mt-3" id="comment-section">
                                    <div className="comment-form-input">
                                        <textarea
                                            className="form-control form-textarea shadow-none"
                                            placeholder="Write your reply..."
                                            value={replyText}
                                            onChange={(e) => setReplyText(e.target.value)}
                                            disabled={isPostingReply}
                                        />
                                    </div>
                                    <div className="mt-2">
                                        <button
                                            className="btn btn-primary btn-sm me-2"
                                            onClick={() => handleReply(comment._id)}
                                            disabled={isPostingReply}
                                        >
                                            {isPostingReply ? (
                                                <>
                                                    <Loader className="w-4 h-4 me-2 animate-spin" />
                                                    Posting...
                                                </>
                                            ) : (
                                                'Post Reply'
                                            )}
                                        </button>
                                        <button
                                            className="btn btn-light btn-sm"
                                            onClick={() => {
                                                setReplyingTo(null);
                                                setReplyText('');
                                            }}
                                            disabled={isPostingReply}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            )}

                            {comment.replies && comment.replies.map((reply) => (
                                <div key={reply._id} className="ms-5 mt-3">
                                    <div className="d-flex gap-3">
                                        <div>
                                            <img
                                                className="rounded-circle"
                                                src={reply.photo || 'https://raw.githubusercontent.com/Shah-Limon/em-list/master/images-dash/profile.jpg'}
                                                alt={reply.name}
                                                width={40}
                                                height={40}
                                            />
                                        </div>
                                        <div>
                                            <h5 className="text-16 fw-semibold text-dark-300">
                                                {reply.name}
                                                {reply.isSeller && <span className="ms-2 badge bg-primary">Seller</span>}
                                            </h5>
                                            <p className="py-2 text-dark-200 fs-6">{reply.reply}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ))
                )}
            </div>
        </div>
    );
};

export default ProductComment;